import { api } from "@/api/api";
import { ApiGetExpenseDto, ApiUpdateExpenseKursadminDto } from "@/api/generated/Api";
import { CourseParticipantExpenseStatus } from "@/shared/enums/CourseParticipantExpenseStatus.enum";
import { ExpenseType } from "@/shared/enums/expenseType.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { StoreState } from "@/store/store.state.interface";
import { Store } from "vuex";

export const updateExpenseEntry = async (
  store: Store<StoreState>,
  expense: ApiGetExpenseDto,
  updateStatus: string,
  notificationMessage: string
) => {
  const updateExpenseBody: ApiUpdateExpenseKursadminDto = {
    courseId: expense.courseId,
    costId: expense.cost?.id,
    description: expense.description,
    amount: expense.amount,
    quantity: expense.quantity,
    status: updateStatus,
    messageToParticipant: expense.messageToParticipant,
  };

  await api.courseparticipantexpense.updateCourseParticipantExpenseDraft(expense.id, updateExpenseBody);
  openNotification(store, NotificationItemType.Success, notificationMessage);
};

export const getExpenseCostList = async (curriculumId: number) => {
  const [curriculum, expenseCostList] = await Promise.all([
    api.curriculum.getCurriculumAsync(curriculumId),
    api.economy.getCostsPerOrganizationAsync({ CostTypeCostGroup: ExpenseType.Expenses }),
  ]);
  return expenseCostList.data.filter((cost) => cost.teachingMethod === curriculum.data.teachingMethod);
};

export const courseParticipantExpenseStatusNorwegian = {
  [CourseParticipantExpenseStatus.Draft]: "Utkast",
  [CourseParticipantExpenseStatus.OrderCreated]: "Ordre opprettet",
  [CourseParticipantExpenseStatus.Rejected]: "Avvist",
  [CourseParticipantExpenseStatus.Validated]: "Validert",
} as const;

export const getCourseParticipantExpenseStatusByStatus = (statusCode: CourseParticipantExpenseStatus) =>
  courseParticipantExpenseStatusNorwegian[statusCode];
