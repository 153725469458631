

































































































































































































import { api, binaryApi } from "@/api/api";
import { ApiGetCostDto, ApiGetExpenseDto, ApiGetKursAdminCourseDto } from "@/api/generated/Api";
import BaseCol from "@/components/shared/form/BaseCol.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { CourseParticipantExpenseStatus } from "@/shared/enums/CourseParticipantExpenseStatus.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import {
  getCourseParticipantExpenseStatusByStatus,
  getExpenseCostList,
  updateExpenseEntry,
} from "@/shared/helpers/courseParticipantExpeseHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateCourseHourInput } from "@/shared/helpers/validationHelpers";
import { useRouteComputed, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";
import fileDownload from "js-file-download";

interface expenseDto extends ApiGetExpenseDto {
  uploadedFile?: File;
  costId: number;
}

export default defineComponent({
  name: "CourseExpenseDetailsPage",
  components: { BaseSheet, BaseCol, BaseSheetHeader, BaseSheetField },
  setup() {
    const store = useStore<StoreState>();
    const route = useRouteComputed();
    const router = useRouter();
    const expenseEntry = ref<expenseDto>();
    const expenseCostList = ref<ApiGetCostDto[]>();
    const courseValues = ref<ApiGetKursAdminCourseDto>();
    const isEditing = ref(false);

    const formatCostTypeItemText = (cost: ApiGetCostDto) => `${cost.name} - ${cost.costTypeName}`.trim();

    const setCostType = (id: number) => {
      if (!expenseEntry.value) {
        return;
      }
      const price = expenseCostList.value?.find((cost) => cost.id === id)?.price;
      if (price === undefined) {
        return;
      }
      expenseEntry.value.amount = price;
      expenseEntry.value.quantity = 1;
    };

    const loadExpense = async () => {
      const expense = (
        await api.courseparticipantexpense.getKursadminCourseParticipantExpense(+route.value.params.expenseId)
      ).data;
      expenseEntry.value = {
        ...expense,
        costId: expense.cost?.id ?? 0,
        courseId: +route.value.params.id,
      };
    };

    const loadExpenseCategories = async () => {
      courseValues.value = (await api.course.getCourseByIdFilteredByDepartmentAsync(+route.value.params.id)).data;
      expenseCostList.value = await getExpenseCostList(courseValues.value.curriculumId);
    };

    const approveExpense = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!expenseEntry.value) {
          return;
        }
        await updateExpenseEntry(
          store,
          expenseEntry.value,
          CourseParticipantExpenseStatus.Validated,
          "Utlegg godkjent"
        );
        await loadExpense();
      });
    };

    const revertExpenseToDraft = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!expenseEntry.value) {
          return;
        }
        await updateExpenseEntry(
          store,
          expenseEntry.value,
          CourseParticipantExpenseStatus.Draft,
          "Utlegg lagret som utkast"
        );
        await loadExpense();
      });
    };

    const declineExpense = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!expenseEntry.value) {
          return;
        }
        await updateExpenseEntry(store, expenseEntry.value, CourseParticipantExpenseStatus.Rejected, "Utlegg avvist");
        await loadExpense();
      });
    };

    const submitExpense = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!expenseEntry.value) {
          return;
        }
        await api.courseparticipantexpense.createOrderFromCourseParticipantExpense(expenseEntry.value.id);
        openNotification(store, NotificationItemType.Success, "Utlegg sendt til Visma");
        await loadExpense();
      });
    };

    const updateExpense = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!expenseEntry.value) {
          return;
        }
        await api.courseparticipantexpense.updateCourseParticipantExpenseDraft(
          +route.value.params.expenseId,
          expenseEntry.value
        );
        openNotification(store, NotificationItemType.Success, "Utlegg oppdatert");
        isEditing.value = false;
      });
    };

    const cancelEdit = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        isEditing.value = false;
        loadExpense();
      });
    };

    const downloadFile = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!expenseEntry.value?.file?.id) {
          return;
        }
        const { id, courseId, courseParticipant, file } = expenseEntry.value;
        const expenseFile = await binaryApi.file.downloadFileAsync(expenseEntry.value.file?.id, { format: "blob" });
        const fileName = `AOF-${courseId}-${courseParticipant?.userFullName}-${id}`;
        fileDownload(expenseFile.data, fileName, file.mimeType || undefined);
      });
    };

    const navigateToExpenseList = () =>
      router.push({
        name: SingleCourseRouteNames.CourseExpenses,
        params: {
          id: route.value.params.id,
        },
      });

    onParamChange(loadExpense);
    onParamChange(loadExpenseCategories);

    return {
      isEditing,
      expenseEntry,
      expenseCostList,
      courseValues,
      cancelEdit,
      formatCostTypeItemText,
      updateExpense,
      setCostType,
      approveExpense,
      declineExpense,
      submitExpense,
      revertExpenseToDraft,
      navigateToExpenseList,
      formatLocalizedDate,
      downloadFile,
      formatCurrency,
      getCourseParticipantExpenseStatusByStatus,
      validateCourseHourInput,
      isValidated: computed(() => expenseEntry.value?.status === CourseParticipantExpenseStatus.Validated),
      isDraft: computed(() => expenseEntry.value?.status === CourseParticipantExpenseStatus.Draft),
      isRejected: computed(() => expenseEntry.value?.status === CourseParticipantExpenseStatus.Rejected),
    };
  },
});
